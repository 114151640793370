import { Car } from './car.model';

export const carArray: Car[] = [
  {
    key: 'kodiaq',
    name: 'Kodiaq',
    imgUrl: './assets/images/cars/kodiaq.jpg',
    iconUrl: './assets/images/cars-icons/kodiaq.jpg',
    priceListUrl: './assets/pdf/cennik_kodiaq.pdf',
    discountValue: '15 500'
  },

  {
    key: 'karoq',
    name: 'Karoq',
    imgUrl: './assets/images/cars/karoq.jpg',
    iconUrl: './assets/images/cars-icons/karoq.jpg',
    priceListUrl: './assets/pdf/cennik_karoq.pdf',
    discountValue: '12 000'
  },

  {
    key: 'kamiq',
    name: 'Kamiq',
    imgUrl: './assets/images/cars/kamiq.jpg',
    iconUrl: './assets/images/cars-icons/kamiq.jpg',
    priceListUrl: './assets/pdf/cennik_kamiq.pdf',
    discountValue: '8 500'
  },
  {
    key: 'superb',
    name: 'Superb',
    imgUrl: './assets/images/cars/superb.jpg',
    iconUrl: './assets/images/cars-icons/superb.jpg',
    priceListUrl: './assets/pdf/cennik_superb.pdf',
    discountValue: '18 500'
  },
  {
    key: 'octavia',
    name: 'Octavia',
    imgUrl: './assets/images/cars/octavia.jpg',
    iconUrl: './assets/images/cars-icons/octavia.jpg',
    priceListUrl: './assets/pdf/cennik_octavia.pdf',
    discountValue: '17 000'
  },
  {
    key: 'scala',
    name: 'Scala',
    imgUrl: './assets/images/cars/scala.jpg',
    iconUrl: './assets/images/cars-icons/scala.jpg',
    priceListUrl: './assets/pdf/cennik_scala.pdf',
    discountValue: '8 500'
  },
  {
    key: 'fabia',
    name: 'Fabia',
    imgUrl: './assets/images/cars/fabia.jpg',
    iconUrl: './assets/images/cars-icons/fabia.jpg',
    priceListUrl: './assets/pdf/cennik_fabia.pdf',
    discountValue: '5 500'
  }
 ];
