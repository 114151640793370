<section id="form">
    <div class="close-icon" *ngIf="formKey ==='offer'">
        <a routerLink="">
            <img src="./assets/images/icons/plus-icon.svg" alt="close icon">
        </a>
    </div>
    <div class="container-padding-x">
        <h2>
            {{formKey === 'offer' ? 'Wypełnij formularz i poznaj ofertę' : ' Poznaj systemy bezpieczeństwa podczas jazdy próbnej' }}
        </h2>
        <form [formGroup]="form" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-12 col-lg-6 pr-lg-5">
                    <div class="form-fields">
                        <div class="form-group">
                            <div class="custom-dropdown model-dropdown">
                                <div class="dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <img [src]="selectedModel.imgUrl" />
                                    <div>
                                        Škoda
                                        <span class="font-black-style ml-1">{{ selectedModel.name }}</span>
                                    </div>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div class="dropdown-item" *ngFor="let model of modelsToSelect"
                                        (click)="handleModelChange(model)">
                                        <span>{{ model.name }}</span>
                                        <img [src]="model.iconUrl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name">Imię</label>
                            <input type="text" class="form-control" id="name" formControlName="name"
                                placeholder="np. Jan" [class.invalid]="isSubmitted && form.controls.name.errors" />
                            <app-error-msg [control]="form.controls.name"></app-error-msg>
                        </div>
                        <div class="form-group">
                            <label for="phoneNo">Telefon</label>
                            <input type="text" class="form-control" id="phoneNo" formControlName="phoneNo"
                                placeholder="np. 506666777" maxlength="9"
                                [class.invalid]="isSubmitted && form.controls.phoneNo.errors" />
                            <app-error-msg [control]="form.controls.phoneNo"></app-error-msg>
                        </div>

                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="np. jan.kowalski@gmail.com" />
                            <div class="error-msg"
                                *ngIf="form.controls['gdpr-answer-email'].value && !form.controls['email'].value">
                                <img src="./assets/images/icons/icon-error.png" />
                                Proszę wypełnić to pole
                            </div>
                            <app-error-msg [control]="form.controls.email"></app-error-msg>
                        </div>

                        <div *ngIf="isSubmitted && form.invalid">
                            <div class="error-msg text-left mb-3">
                                Aby zapisać się na jazdę próbną podaj imię oraz numer telefonu.
                            </div>
                        </div>
                    </div>

                    <div class="form-approvals">
                        <div class="font-black-style font16">Zgody opcjonalne</div>
                        <div class="error-msg text-left mb-2" *ngIf="
                        !form.controls['gdpr-answer-marketing-messages-importer'].value &&
                        (form.controls['gdpr-answer-email'].value ||
                        form.controls['gdpr-answer-electronic-messages'].value ||
                        form.controls['gdpr-answer-voice-calls'].value)">
                            Proszę wyrazić zgodę na przetwarzanie danych.
                        </div>
                        <div class="form-group">
                            <div class="custom-checkbox">
                                <label class="container font12 text-justify">
                                    Zgadzam się na używanie przez Volkswagen Group Polska Sp.&nbsp;z&nbsp;o.o. (VGP)
                                    oraz ŠKODA Auto a.s. przekazanych danych,
                                    danych zebranych o&nbsp;moich zapytaniach i&nbsp;zainteresowaniach, ofertach,
                                    zamówieniach i&nbsp;zleceniach w&nbsp;ramach relacji
                                    biznesowej z&nbsp;VGP lub z&nbsp;Autoryzowanym Dealerem lub Autoryzowanym Partnerem
                                    Serwisowym, w&nbsp;celu marketingu dot.
                                    produktów i&nbsp;usług marki ŠKODA oraz innych powiązanych z&nbsp;marką ŠKODA
                                    akcesoriów, produktów i&nbsp;usług motoryzacyjnych,
                                    w&nbsp;tym także w&nbsp;celu profilowania na potrzeby marketingu oraz realizacji
                                    działań posprzedażowych polegających na
                                    prowadzeniu i&nbsp;analizie badań: satysfakcji klienta lub zainteresowania marką,
                                    produktami i&nbsp;usługami marki ŠKODA.
                                    <input type="checkbox" formControlName="gdpr-answer-marketing-messages-importer" />
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="font-black-style font16">
                            Chcę otrzymywać w/w informacje handlowe i&nbsp;marketingowe poprzez:
                        </div>
                        <div class="error-msg text-left mb-2" *ngIf="
                            form.controls['gdpr-answer-marketing-messages-importer'].value &&
                            !(
                                form.controls['gdpr-answer-email'].value ||
                                form.controls['gdpr-answer-electronic-messages'] .value ||
                                form.controls['gdpr-answer-voice-calls'].value
                            )">
                            Proszę wybrać jeden z kanałów
                        </div>
                        <div class="gdpr-labels">
                            <div class="custom-checkbox" (mouseup)="fontBold('email')">
                                <label [class.show-info]="email">
                                    E-mail
                                    <input type="checkbox" formControlName="gdpr-answer-email" />
                                    <span></span>
                                </label>
                            </div>
                            <div class="custom-checkbox" (mouseup)="fontBold('electronic')">
                                <label [class.show-info]="electronic">
                                    SMS/MMS
                                    <input type="checkbox" formControlName="gdpr-answer-electronic-messages" />
                                    <span></span>
                                </label>
                            </div>
                            <div class="custom-checkbox" (mouseup)="fontBold('calls')">
                                <label [class.show-info]="calls">
                                    Telefon
                                    <input type="checkbox" formControlName="gdpr-answer-voice-calls" />
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-lg-left my-2">
                        <button class="btn-green" type="submit">Wyślij</button>
                    </div>
                </div>

                <div class="col-12 col-lg-6 pl-lg-5">
                    <div class="form-approvals-info pt-4 pt-lg-0">
                        <div>
                            <div *ngIf="formKey !== 'offer'">
                                <p class="mb-3">
                                    Pozostawienie przez Ciebie danych i&nbsp;wysłanie formularza oznacza Twoją zgodę dla
                                    Volkswagen Group Polska sp.&nbsp;z&nbsp;o.o. oraz poprzez
                                    Twój wybór także dla wybranego Autoryzowanego Dealera ŠKODY, dane Dealera możesz
                                    znaleźć na stronie:
                                    <a href="https://dealers.skoda-auto.com/456/pl-pl"
                                        target="_blank">https://dealers.skoda-auto.com/456/pl-pl</a>
                                    na skontaktowanie się z&nbsp;Tobą w&nbsp;celu zaproponowania i&nbsp;umówienia jazdy
                                    testowej z&nbsp;użyciem telefonu lub adresu e-mail (jeśli chcesz lub go zostawisz).
                                    Oddzwonimy do Ciebie, zweryfikujemy Twoje dane i&nbsp;potwierdzimy Twoje
                                    zainteresowanie informacjami na temat jazdy testowej.
                                    Następnie Twoje zapytanie przekażemy wybranemu przez Ciebie Autoryzowanemu Dealerowi
                                    ŠKODA, który skontaktuje z&nbsp;Tobą celem obsługi
                                    Twojego zapytania (Preferowany Dealer). Podanie Twoich danych jest dobrowolne, lecz
                                    niezbędne do obsługi Twojego zapytania.
                                </p>
                            </div>
                            <div *ngIf="formKey === 'offer'">
                                <p class="mb-3">
                                    Pozostawienie przez Ciebie danych i wysłanie formularza oznacza Twoją zgodę dla
                                    Volkswagen Group Polska sp. z o.o. oraz poprzez Twój wybór także dla wybranego
                                    Autoryzowanego Dealera Škoda, dane Dealera możesz znaleźć na stronie
                                    <a href="https://dealers.skoda-auto.com/456/pl-pl"
                                        target="_blank">https://dealers.skoda-auto.com/456/pl-pl</a> na skontaktowanie
                                    się z Tobą w celu
                                    przedstawienia Ci informacji na temat zakupu samochodu marki Škoda oraz ogólnych
                                    informacji dot. możliwych sposobów finansowania z użyciem telefonu lub adresu e-mail
                                    (jeśli chcesz lub go zostawisz). Oddzwonimy do Ciebie, zweryfikujemy Twoje dane i
                                    potwierdzimy Twoje zainteresowanie informacjami na temat oferty. Następnie Twoje
                                    zapytanie przekażemy wybranemu przez Ciebie Autoryzowanemu Dealerowi Škoda, który
                                    skontaktuje z Tobą celem obsługi Twojego zapytania (Preferowany Dealer). Podanie
                                    Twoich danych jest dobrowolne, lecz niezbędne do obsługi Twojego zapytania.
                                </p>
                            </div>
                            <p class="mb-3">
                                Administratorem przekazanych danych jest Volkswagen Group Polska sp.&nbsp;z&nbsp;o.o.
                                z&nbsp;siedzibą w&nbsp;Poznaniu (61-037), ul.&nbsp;Krańcowa&nbsp;44
                                wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy w&nbsp;Poznaniu –
                                Nowe Miasto i&nbsp;Wilda, VIII Wydział Gospodarczy
                                pod numerem KRS 0000327143, NIP 782-24-63-563, REGON 301062169 (VGP) oraz Preferowany
                                Dealer, działający na zasadzie współadministrowania.
                            </p>
                            <p class="mb-3">
                                W&nbsp;każdej chwili możesz cofnąć swoją zgodę i&nbsp;zrezygnować z&nbsp;otrzymania
                                informacji dot. oferty informując konsultanta Centrum Informacji
                                lub Preferowanego Dealera o&nbsp;rezygnacji z&nbsp;dalszego kontaktu w&nbsp;tym zakresie
                                (utrata zainteresowania ofertą będzie dla nas jednoznaczna
                                z&nbsp;cofnięciem Twojej zgody na obsługę tego zapytania). Swoje dodatkowe zgody możesz
                                cofnąć poprzez formularz umieszczony na stronie
                                internetowej <a href="https://twoja.skoda.pl/formularze/formularz-cofniecia-zgod"
                                    target="_blank">
                                    https://twoja.skoda.pl/formularze/formularz-cofniecia-zgod</a> albo bezpośrednio
                                w&nbsp;kontakcie z&nbsp;VGP lub z&nbsp;Autoryzowanym
                                Dealerem marki ŠKODA.
                            </p>

                            <p class="font-green mb-3 cursor-pointer" *ngIf="!isMoreInfoOpened"
                                (click)="isMoreInfoOpened = !isMoreInfoOpened" style="text-decoration: underline;">
                                Zobacz więcej
                            </p>

                            <div class="more-info" [class.show]="isMoreInfoOpened">
                                <p class="font-bold mb-3">Cele i podstawy prawne</p>
                                <div>
                                    <div *ngIf="formKey === 'offer'">
                                        <p>1) W związku z Twoim zapytaniem&nbsp;o szczegóły oferty zakupu pojazdu marki
                                            ŠKODA VGP wykorzysta dane
                                            podane
                                            przez Ciebie w formularzu, bądź później podczas rozmowy, do kontaktu z Tobą
                                            celem potwierdzenia Twojego
                                            zainteresowania, przedstawienia Ci informacji na temat produktu / usługi
                                            marki ŠKODA, którym jesteś
                                            zainteresowany, z użyciem telefonu lub e-mail (jeśli go podałeś),&nbsp;a
                                            Preferowany Dealer dalej obsłuży
                                            Twoje
                                            zapytanie, w oparciu&nbsp;o Twoją zgodę wyrażoną poprzez pozostawienie
                                            Twoich danych, wybór dealera&nbsp;i
                                            wysłanie
                                            formularza.</p>
                                        <p>Przygotowanie konkretnej oferty na Twoje żądanie nastąpi na podstawie
                                            czynności zmierzających do zawarcia
                                            umowy, chyba że kontaktujesz się z nami jako pracownik lub osoba
                                            kontaktowa&nbsp;w imieniu klienta
                                            korporacyjnego – wówczas Twoje dane osobowe przetwarzane będą w ramach
                                            uzasadnionych interesów
                                            polegających na możliwości bieżącego kontaktu w ramach takiego zapytania.
                                        </p>
                                    </div>
                                    <div *ngIf="formKey !== 'offer'">
                                        <p>1) W związku z Twoim zapytaniem&nbsp;o umówienie jazdy testowej pojazdu marki
                                            ŠKODA VGP wykorzysta podane
                                            przez Ciebie dane na formularzu lub później w trakcie rozmowy, do kontaktu z
                                            Tobą celem potwierdzenia
                                            Twojego zainteresowania umówieniem jazdy testowej, z użyciem telefonu lub
                                            adresu email (jeśli go dodatkowo
                                            podasz),&nbsp;a Preferowany Dealer dalej obsłuży Twoje zapytanie, w
                                            oparciu&nbsp;o Twoją zgodę wyrażoną
                                            poprzez
                                            pozostawienie Twoich danych, wybór dealera&nbsp;i wysłanie formularza.</p>
                                        <p>Realizacja jazdy testowej nastąpi na podstawie umowy, chyba że kontaktujesz
                                            się&nbsp;z nami jako
                                            pracownik lub
                                            osoba kontaktowa w imieniu klienta korporacyjnego – wówczas Twoje dane
                                            osobowe przetwarzane będą w ramach
                                            uzasadnionych interesów polegających na możliwości bieżącego kontaktu w
                                            ramach takiego zapytania.</p>
                                    </div>
                                    <div class="mb-3">
                                        <p>2) Jeśli wyrazisz dodatkowe zgody marketingowe na przyszłość, to VGP oraz
                                            ŠKODA Auto a.s. z siedzibą Mladá Boleslav, tř. Václava Klementa 869, 293 01
                                            Mladá Boleslav, Czechy, jako współadministratorzy mogą na podstawie Twojej
                                            zgody wykorzystać dane wskazane w zgodzie w celu:</p>
                                        <p>- oferowania Tobie produktów i usług marki ŠKODA oraz akcesoriów, produktów i
                                            usług motoryzacyjnych powiązanych z marką ŠKODA na podstawie Twojej zgody
                                            (marketing); w oparciu o Twoją zgodę VGP może przesyłać informacje handlowe
                                            i marketingowe wybranym przez Ciebie sposobem komunikacji;</p>
                                        <p>- w celu dobierania ofert produktów i usług marki ŠKODA oraz akcesoriów,
                                            produktów i usług motoryzacyjnych powiązanych z marką ŠKODA pod kątem Twoich
                                            potrzeb, zainteresowania, profilu – tj. w celu profilowania, w tym także z
                                            wykorzystaniem procesów automatycznych, polegającego na zestawianiu Twoich
                                            danych w zakresie udzielonej zgody, pochodzących z różnych źródeł (w tym
                                            danych zebranych przez VGP, Autoryzowanego Dealera lub Autoryzowanego
                                            Partnera Serwisowego, stron internetowych) i przypisanego na tej podstawie
                                            profilu, którego konsekwencją jest dobranie i przedstawienie Tobie reklam
                                            lub ofert odpowiadających Twojemu profilowi, w oparciu o Twoją zgodę. Jeśli
                                            w jakimś zakresie skierowana do Ciebie reklama lub oferta nie odpowiada
                                            Twoim potrzebom lub zainteresowaniom, bądź powinniśmy uwzględnić inne
                                            czynniki, skontaktuj się z nami;</p>
                                        <p>- w celu realizacji działań posprzedażowych polegających na prowadzeniu i
                                            analizie i badań: satysfakcji klienta lub zainteresowania marką, produktami
                                            i usługami marki ŠKODA.</p>
                                    </div>
                                    <div class="mb-3">
                                        <p>3) W ramach uzasadnionego interesu wykorzystamy Twoje dane celem:</p>
                                        <p>- wysłania zaproszenia do badania zadowolenia obsługi Twojego zapytania przez
                                            nasze Centrum Informacji;</p>
                                        <p>- badania jakości obsługi w ramach sieci dealerskiej w celach archiwalnych
                                            (dowodowych) celem zabezpieczenia informacji na wypadek prawnej potrzeby
                                            wykazania faktów, ewentualnego ustalenia, dochodzenia lub obrony przed
                                            roszczeniami;</p>
                                        <p>- w celach analitycznych i statystycznych, lepszego doboru produktów i usług
                                            do potrzeb naszych klientów, ogólnej optymalizacji naszych produktów,
                                            optymalizacji procesów obsługi, budowania wiedzy o naszych klientach;</p>
                                        <p>- audytowania i raportowania wewnętrznego oraz sieci dealerskiej, weryfikacji
                                            działalności Autoryzowanych Dealerów marki ŠKODA oraz dokonywania rozliczeń
                                            finansowych;</p>
                                        <p>- zapewnieniu, iż produkty lub usługi marki ŠKODA dostarczane są zgodnie z
                                            prawem oraz obowiązującym standardami i wytycznymi producenta, umowami
                                            dealerskimi oraz w celach zapobiegania i wykrywania nadużyć;</p>
                                        <p>- w celu realizacji Twoich praw, aktualizacji Twoich danych, prawa do
                                            cofnięcia zgody oraz przechowywania żądań Użytkowników oraz dowodów na ich
                                            obsługę;</p>
                                        <p>- zarządzania procesem sprzedaży i obsługi klienta, ustalania standardów
                                            obsługi klientów marki ŠKODA, zapewnienia dbałości o jakość produktów i
                                            usług, prowadzenia centralnej bazy klientów, w tym klientów potencjalnych.
                                        </p>
                                    </div>
                                    <p class="mb-3">
                                        4) W związku z korzystaniem przez Ciebie ze strony internetowej marki ŠKODA, w
                                        zależności od Twoich ustawień cookies VGP może gromadzić także informacje
                                        zawarte plikach cookies lub podobnych technologiach w celu analizowania
                                        zachowań, lepszego dostosowania oferty produktów lub usług oraz wyświetlania
                                        reklamy produktów lub usług marki, w tym profilowania. Więcej informacji w
                                        naszej
                                        <a href="http://www.skoda-auto.pl/inne/polityka_prywatnosci"
                                            target="_blank">Polityce prywatności</a> oraz <a
                                            href="http://www.skoda-auto.pl/inne/polityka-plikow-cookies"
                                            target="_blank">Polityce cookies</a>.
                                    </p>
                                    <p class="mb-3"><span class="font-bold">Kontakt z inspektorem ochrony danych
                                            VGP</span>: Możesz skontaktować się z IOD VGP pod adresem e-mail
                                        iod@vw-group.pl, a także pisemnie na adres Volkswagen Group Polska sp. z o.o. z
                                        siedzibą w Poznaniu (61-037), ul. Krańcowa 44 z dopiskiem „IOD”;</p>
                                    <p class="mb-3"><span class="font-bold">Odbiorcy danych:</span> „Preferowany Dealer.
                                        Ponadto odbiorcami Twoich danych osobowych będą także firmy świadczące usługi na
                                        nasze zlecenie, którym zlecimy czynności wymagające przetwarzania danych, w
                                        szczególności w zakresie usług IT, usług marketingowych (m.in. agencje
                                        marketingowe), badań rynku, firmy wspierające organizację i realizację wydarzeń,
                                        call center, audytorzy doradcy, a w zakresie dodatkowej zgody także Autoryzowani
                                        Dealerzy lub Partnerzy Serwisowi marki Škoda działający w tym zakresie na
                                        zlecenie VGP. Odbiorcami danych mogą być także spółki z grupy, w tym Volkswagen
                                        AG oraz Škoda Auto a.s. oraz spółki Volkswagen Financial Services tj. Volkswagen
                                        Bank Polska S.A., Volkswagen Financial Services Polska Sp. z o.o, Volkswagen
                                        Serwis Ubezpieczeniowy Sp. z o.o.”</p>
                                    <p class="mb-3"><span class="font-bold">Okres przechowywania:</span> „Twoje dane
                                        osobowe w związku z Twoim zapytaniem będziemy przetwarzać przez okres niezbędny
                                        do jego obsługi (nie dłużej niż 6 miesięcy jeśli zrezygnujesz z rozmowy, oferty
                                        bądź wcześniej jeśli cofniesz zgodę; w przypadku reklamacji – przez czas
                                        niezbędny do jej realizacji i przedawnienia roszczeń). Jeśli wyrazisz dodatkowe
                                        zgody, to Twoje dane osobowe przetwarzane będą w celach marketingowych przez
                                        okres w jakim te cele wynikające ze zgody pozostają ważne, ale nie dłużej niż 5
                                        lat od momentu wyrażenia zgody, a jeśli cofniesz zgodę to do momentu cofnięcia
                                        zgody. W odniesieniu do pozostałych celów opartych na naszym uzasadnionym
                                        interesie przez okres ważności tych celów albo do czasu wniesienia przez Ciebie
                                        sprzeciwu, w każdym przypadku nie dłużej niż 3 lata, chyba że zaistnieje
                                        konieczność przechowywania danych dłużej, gdy obowiązek taki wynika z przepisów
                                        prawa lub będzie to konieczne do ustalenia, obrony lub dochodzenia roszczeń (np.
                                        do chwili przedawnienia roszczeń możemy przechowywać dowód udzielonej zgody).”
                                    </p>
                                    <p class="mb-3">
                                        <span class="font-bold">Pouczenie o prawach:</span> masz prawo dostępu do treści
                                        swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia
                                        przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym
                                        momencie poprzez formularz umieszczony na stronie internetowej
                                        <a href="https://twoja.skoda.pl/formularze/formularz-cofniecia-zgod"
                                            target="_blank">https://twoja.skoda.pl/formularze/formularz-cofniecia-zgod</a>
                                        lub przez kontakt z nami, a także u Autoryzowanego Dealera lub Autoryzowanego
                                        Partnera Serwisowego marki ŠKODA; cofnięcie zgody pozostaje bez wpływu na
                                        zgodność z prawem przetwarzania Twoich danych, którego dokonano na podstawie
                                        zgody przed jej cofnięciem. Możesz zrealizować swoje prawa m.in. przez kontakt
                                        pisemny lub na adres email:
                                        <a href="mailto:daneosobowe@vw-group.pl">daneosobowe@vw-group.pl</a>, także w
                                        odniesieniu do Centrali.
                                    </p>
                                    <p class="mb-3">W przypadku współadministrowania z Preferowanym Dealerem,
                                        wskazujemy, iż każdy ze współadministratorów będzie we własnym zakresie
                                        odpowiedzialny za realizację praw podmiotów danych z uwagi na to, iż poza
                                        wspólnymi systemami lub platformami, dane klientów lub potencjalnych klientów
                                        mogą być przetwarzane przez Preferowanego Dealera także w niezależnych procesach
                                        lub systemach IT. Niemniej w każdym przypadku można skontaktować się z VGP lub
                                        IOD VGP (dane kontaktowe powyżej).</p>
                                    <p class="mb-3"><span class="font-bold">Prawo do wniesienia sprzeciwu</span>: masz
                                        prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych w związku z
                                        celami opartymi na podstawie naszego prawnie uzasadnionego interesu, opisanego
                                        powyżej (w takim przypadku przestaniemy przetwarzać Twoje dane w tych celach,
                                        chyba że będziemy w stanie wykazać, że w stosunku do tych danych istnieją dla
                                        nas ważne prawnie uzasadnione podstawy, które są nadrzędne wobec Twoich
                                        interesów, praw i wolności, lub dane będą nam niezbędne do ewentualnego
                                        ustalenia, dochodzenia lub obrony roszczeń). Masz prawo do sprzeciwu wobec
                                        marketingu bezpośredniego, w tym profilowania.</p>
                                    <p class="mb-3"><span class="font-bold">Prawo do skargi:</span> masz prawo
                                        wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uznasz, że
                                        przetwarzanie danych osobowych narusza przepisy o ochronie danych osobowych.</p>
                                </div>
                            </div>

                            <p class="font-green mb-3 cursor-pointer" *ngIf="isMoreInfoOpened"
                                (click)="isMoreInfoOpened = !isMoreInfoOpened" style="text-decoration: underline;">
                                Zwiń
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
