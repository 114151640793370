import { ShareModule } from './share/share.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { FormComponent } from './form/form.component';
import { HeaderComponent } from './header/header.component';
import { SafetyComponent } from './safety/safety.component';
import { ModeleComponent } from './modele/modele.component';
import { ExperienceComponent } from './experience/experience.component';
import { ErrorMsgComponent } from './form/error-msg/error-msg.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModeleItemComponent } from './modele/modele-item/modele-item.component';
import { MobileSystemGroupComponent } from './safety/mobile-system-group/mobile-system-group.component';
import { FormRouterComponent } from './form-router/form-router.component';
import { MainComponent } from './main/main.component';
import { AppRoutingModule } from './app-routing.module';
import { PowerOfSmallThingsComponent } from './safety/power-of-small-things/power-of-small-things.component';
import { SpeedComponent } from './safety/power-of-small-things/speed/speed.component';
import { ImageSectionComponent } from './safety/power-of-small-things/image-section/image-section.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    FormComponent,
    HeaderComponent,
    SafetyComponent,
    ModeleComponent,
    ExperienceComponent,
    ErrorMsgComponent,
    AlertMessageComponent,
    ModeleItemComponent,
    MobileSystemGroupComponent,
    FormRouterComponent,
    MainComponent,
    PowerOfSmallThingsComponent,
    SpeedComponent,
    ImageSectionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ShareModule,
    AppRoutingModule,
      NgxSliderModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
