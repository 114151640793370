<div *ngIf="currentAlert" class="alert-overflow">
  <div class="alert">
    <div
      class="close-icon font40 cursor-pointer"
      (click)="closeAlert()"
    >
      <img src="./assets/images/icons/ico-close-alert-black.png">
    </div>
    <div>
      <p class="title">{{ currentAlert.titleCopy }}</p>
      <p class="font20 font-regural my-4" [innerHTML]="currentAlert.messageCopy"></p>
    </div>
  </div>
</div>
