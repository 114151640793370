import {MainComponent} from './main/main.component';
import {FormRouterComponent} from './form-router/form-router.component';


import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PowerOfSmallThingsComponent} from './safety/power-of-small-things/power-of-small-things.component';


const routes: Routes = [
    {path: '', component: PowerOfSmallThingsComponent},
    {path: 'offer', component: FormRouterComponent},
    {path: '', redirectTo: '', pathMatch: 'full'},
    {path: 'safety', component: MainComponent},
    {path: 'dzielimyrazemdroge', loadChildren: () => import('./road/road.module').then(m => m.RoadModule)},
    {path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule)},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        enableTracing: false,
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
