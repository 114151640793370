import {Component, Input, OnInit} from '@angular/core';
import {Power} from '../power.model';

@Component({
    selector: 'image-section',
    templateUrl: './image-section.component.html',
    styleUrls: ['./image-section.component.scss']
})
export class ImageSectionComponent implements OnInit {
    @Input()
    data: Power;
    constructor() {
    }

    ngOnInit(): void {
    }
}
