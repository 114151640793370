<div class="container-background">
    <section class="speed-measurement">
        <div class="content-speed">
            <div class="items-container">
                <div *ngFor="let item of list; index as i" [className]="'item item-' + item.id"><img [src]="item.imgUrl"
                                                                                                     alt="">
                    <div class="weight-container" *ngIf="item.weight && copy[i]">
                        <div class="weight">
                            <p class="number">{{copy[i].weight}}</p>
                            <p class="kilograms">kg</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="speed">
                <h3 class="text">Prędkość <span class="font-green">{{value}}km/h</span></h3>
                <p class="sub-text">Waga łączna <span>{{weight}}kg</span></p>
            </div>
        </div>
        <div class="slider">
            <ngx-slider (valueChange)="changeWeight($event)" [(value)]="value" [options]="options"></ngx-slider>
        </div>
    </section>
</div>
