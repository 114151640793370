<h2 [ngClass]="{'section-title': true, 'green': data.titleGreen}">{{data.title}} <span *ngIf="data.titleGreen">Zobacz, jaką mają siłę:</span></h2>
<section class="wrapper">
    <div class="container" *ngFor="let item of data.sections">
        <div class="image">
            <img *ngIf="!item.videoUrl" src="{{item.imgUrl}}" alt="{{item.title}}">
            <video [muted]="'muted'" *ngIf="item.videoUrl && item.videoBreak" src="./assets/images/power-of-small-things/Skoda%20-%20hamowanie.mov" autoplay loop  width="100%" height="100%" controls></video>
            <video [muted]="'muted'" *ngIf="item.videoUrl && !item.videoBreak" src="./assets/images/power-of-small-things/Skoda%20-%20dachowanie%20v2.mov" autoplay loop width="100%" height="100%" controls></video>
        </div>
        <h2 class="section-heading" >{{item.title}}</h2>
        <p class="text">{{item.description}}</p>
        <a  href="{{item.buttonUrl}}" *ngIf="item.buttonUrl">
            <button class="btn">Zobacz więcej</button>
        </a>
    </div>
</section>
