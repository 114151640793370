import { System } from './system.model';

export const systems: System[] = [
    {
        id: 0,
        groupId: 0,
        name: 'SIDE ASSIST',
        shortDescription: '- funkcja monitorowania martwego pola',
        description: `
        SIDE ASSIST dba o\u00A0bezpieczeństwo podczas wyprzedzania i\u00A0zmiany pasa. Monitoruje tzw. martwe pole, czyli obszar
        wokół pojazdu, który jest niewidoczny w\u00A0lusterkach. Czujniki wykrywają auta znajdujące się nawet 70 metrów za
        samochodem i\u00A0zapalają światło ostrzegawcze w\u00A0lusterku bocznym.`,
        videoUrl: window.location.origin + '/video/side_assist.mp4',
        imgUrl: './assets/images/safety-systems/side_assist.jpg'
    },
    {
        id: 1,
        groupId: 0,
        name: 'FRONT ASSIST',
        shortDescription: '- kontrola odstępu z\u00A0funkcją awaryjnego hamowania',
        description: `
        FRONT ASSIST to system ostrzegający i\u00A0zapobiegający kolizji lub minimalizujący jej skutki. Gdy kierowca zareaguje
        z\u00A0opóźnieniem na ostrzeżenie przed przeszkodę na drodze, uruchomi się funkcja awaryjnego hamowania.`,
        videoUrl: window.location.origin + '/video/front_assist.mp4',
        imgUrl: './assets/images/safety-systems/front_assist.jpeg'
    },
    {
        id: 2,
        groupId: 0,
        name: 'CREW PROTECT ASSIST',
        shortDescription: '- aktywna funkcja ochrony kierowcy i\u00A0pasażera',
        description: `
        Funkcja aktywnej ochrony pasażerów, która pozwala zminimalizować skutki ewentualnej kolizji. W\u00A0przypadku zagrożenia
        system automatycznie napina pasy bezpieczeństwa i\u00A0domyka wszystkie okna – także okno dachowe.`,
        videoUrl: window.location.origin + '/video/crew_protect_assist.mp4',
        imgUrl: './assets/images/safety-systems/crew_protect_assist.jpg'
    },
    {
        id: 3,
        groupId: 0,
        name: 'LANE ASSIST',
        shortDescription: '- asystent pasa ruchu',
        description: `
        Asystent pasa ruchu zapobiega niekontrolowanej zmianie pasa. Wykrywa namalowane na jezdni linie i\u00A0gdy zbytnio zbliżysz
        się do nich bez włączenia kierunkowskazu, system skoryguje tor jazdy lekkim ruchem kierownicy.`,
        videoUrl: window.location.origin + '/video/lane_assist.mp4',
        imgUrl: './assets/images/safety-systems/lane_assist.jpeg'
    },
    {
        id: 4,
        groupId: 0,
        name: 'PEDESTRIAN PROTECTION',
        shortDescription: '',
        description: `
        Przednie czujniki monitorują ruch pieszych przed samochodem. Gdy na ulicy niespodziewanie pojawi się człowiek,
        system wyświetli ostrzeżenie na desce rozdzielczej, a\u00A0w\u00A0ostateczności uruchomi funkcję hamowania awaryjnego.`,
        videoUrl: window.location.origin + '/video/pedestrian_protection.mp4',
        imgUrl: './assets/images/safety-systems/pedestrian_protection.jpg'
    },
    {
        id: 5,
        groupId: 0,
        name: 'ADAPTIVE CRUISE CONTROL',
        shortDescription: '- aktywny tempomat',
        description: `
        Aktywny tempomat (ACC) utrzymuje bezpieczną odległość od pojazdu jadącego z\u00A0przodu, a\u00A0dodatkowo hamuje i\u00A0przyspiesza
        razem z\u00A0nim. System łączy w\u00A0sobie tempomat z\u00A0kontrolą odległości i\u00A0jest aktywny zarówno przy niskich, jak
        i\u00A0wysokich prędkościach.`,
        videoUrl: window.location.origin + '/video/adaptive_cruise_control.mp4',
        imgUrl: './assets/images/safety-systems/adaptive_cruise_control.jpeg'
    },
    {
        id: 6,
        groupId: 0,
        name: 'Telefon alarmowy',
        shortDescription: '',
        description: `
        W\u00A0przypadku awarii na drodze wystarczy jeden przycisk, żeby połączyć się z\u00A0pracującymi całą dobę operatorami.
        Zidentyfikują oni Twój problem i\u00A0pomogą go rozwiązać, a\u00A0w\u00A0razie potrzeby zamówią dla Ciebie usługę asisstance.`,
        videoUrl: window.location.origin + '/video/break_down_call.mp4',
        imgUrl: './assets/images/safety-systems/break_down_call.jpg'
    },
    {
        id: 7,
        groupId: 0,
        name: 'DRIVER ALERT',
        shortDescription: '- system wykrywający zmęczenie kierowcy',
        description: `
        System najpierw zbiera dane dotyczące sposobu prowadzenia samochodu, a\u00A0potem na bieżąco je analizuje,
        mierząc poziom skupienia kierowcy. Gdy wykryje, że aktualny styl jazdy odbiega od wyników początkowych,
        wyświetli komunikat sugerujący zatrzymanie auta i\u00A0odpoczynek.`,
        videoUrl: window.location.origin + '/video/driver_alert.mp4',
        imgUrl: './assets/images/safety-systems/driver_alert.jpg'
    },
    {
        id: 8,
        groupId: 0,
        name: 'TRAFFIC SIGN RECOGNITION',
        shortDescription: '- asystent pasa ruchu',
        description: `
        Funkcja rozpoznawania znaków drogowych Traffic Sign Recognition wykorzystuje skanującą otoczenie przednią kamerę oraz dane
        z\u00A0nawigacji do wykrywania określonych znaków drogowych. System ma za zadanie przypominać kierowcy m.in. o\u00A0ograniczeniach
        prędkości i\u00A0zakazach wyprzedzania lub wjazdu. `,
        videoUrl: window.location.origin + '/video/traffic_sign_recognition.mp4',
        imgUrl: './assets/images/safety-systems/traffic_sign_recognition.jpeg'
    },
    {
        id: 9,
        groupId: 1,
        name: 'SIDE ASSIST',
        shortDescription: '- funkcja monitorowania martwego pola',
        description: `
        SIDE ASSIST dba o\u00A0bezpieczeństwo podczas wyprzedzania i\u00A0zmiany pasa. Monitoruje tzw. martwe pole, czyli obszar
        wokół pojazdu, który jest niewidoczny w\u00A0lusterkach. Czujniki wykrywają auta znajdujące się nawet 70 metrów za
        samochodem i\u00A0zapalają światło ostrzegawcze w\u00A0lusterku bocznym.`,
        videoUrl: window.location.origin + '/video/side_assist.mp4',
        imgUrl: './assets/images/safety-systems/side_assist.jpg'
    },
    {
        id: 10,
        groupId: 1,
        name: 'Kamera AREA VIEW 360',
        shortDescription: '',
        description: `
        Zestaw kamer umieszczonych z\u00A0przodu i\u00A0tyłu auta oraz w\u00A0obudowach lusterek. Rejestrują one panoramiczny widok
        wokół pojazdu, który kierowca może zobaczyć na wyświetlaczu centralnym. System ostrzega o\u00A0ryzyku kolizji podczas parkowania
        i\u00A0manewrów
        w wąskich uliczkach.`,
        videoUrl: window.location.origin + '/video/area_view_camera.mp4',
        imgUrl: './assets/images/safety-systems/area_view_camera.jpg'
    },
    {
        id: 11,
        groupId: 1,
        name: 'REAR TRAFFIC ALERT',
        shortDescription: '',
        description: `
        System REAR TRAFFIC ALERT jest przydatny szczególnie podczas wyjazdu z\u00A0miejsca parkingowego. Wykrywa pojazdy zbliżające się
        z\u00A0tyłu lub z\u00A0boku i\u00A0ostrzega kierowcę sygnałem dźwiękowym i\u00A0znakami wyświetlanymi na desce rozdzielczej.
        W\u00A0ostateczności sam uruchamia hamulec awaryjny.`,
        videoUrl: window.location.origin + '/video/rear_traffic_alert.mp4',
        imgUrl: './assets/images/safety-systems/rear_traffic_alert.jpeg'
    },
    {
        id: 12,
        groupId: 1,
        name: 'MANOEUVRE ASSIST',
        shortDescription: '',
        description: `
        Funkcja MANOEUVRE ASSIST korzysta z obecnych w\u00A0samochodzie czujników parkowania i\u00A0systemów stabilizacji toru
        jazdy przy niskich prędkościach. Rozpoznaje i\u00A0reaguje na napotkane przeszkody, najpierw wysyłając kierowcy wizualne
        i\u00A0dźwiękowe ostrzeżenia, a\u00A0w\u00A0ostateczności hamując. `,
        videoUrl: window.location.origin + '/video/manoeuvre_assist.mp4',
        imgUrl: './assets/images/safety-systems/manoeuvre_assist.jpeg'
    },
    {
        id: 13,
        groupId: 1,
        name: 'PARK ASSIST',
        shortDescription: '- asystent parkowania',
        description: `
        Podczas parkowania system przejmuje kontrolę nad kierownicą i\u00A0lekkimi ruchami pomaga wykonać manewr w\u00A0optymalny sposób.
        Kierowca musi tylko dodawać gazu lub hamować. PARK ASSIST potrafi także znaleźć odpowiednie miejsce do parkowania prostopadłego
        (o\u00A0ile prędkość auta nie przekracza 40 km/h). `,
        videoUrl: window.location.origin + '/video/park_assist.mp4',
        imgUrl: './assets/images/safety-systems/park_assist.jpg'
    },
    {
        id: 14,
        groupId: 1,
        name: 'PARKING DISTANCE CONTROL',
        shortDescription: '- czujniki parkowania z tyłu i z przodu',
        description: `
        System PARKING DISTANCE CONTROL zapobiega kolizjom podczas parkowania w\u00A0ciasnych miejscach. W\u00A0przednim i\u00A0tylnym
        zderzaku zastosowano czujniki ultradźwiękowe, które wykrywają przeszkody i\u00A0wysyłają wizualne oraz dźwiękowe sygnały,
        pomagając w\u00A0manewrach. `,
        videoUrl: window.location.origin + '/video/parking_distance.mp4',
        imgUrl: './assets/images/safety-systems/parking_distance.jpeg'
    },
    {
        id: 15,
        groupId: 2,
        name: 'AUTO LIGHT ASSIST',
        shortDescription: '- automatyczne włączanie i wyłączanie świateł drogowych',
        description: `
        System AUTO LIGHT ASSIST wykorzystuje przednią kamerę do wykrywania nadjeżdżających pojazdów i\u00A0automatycznie przełącza
        między światłami drogowymi a\u00A0mijania. Wiązka światła z wielosegmentowego reflektora regulowana jest tak, żeby nie razić
        innych uczestników ruchu, a\u00A0jednocześnie dobrze oświetlać trasę przed autem.`,
        videoUrl: window.location.origin + '/video/auto_light_assist.mp4',
        imgUrl: './assets/images/safety-systems/auto_light_assist.jpeg'
    },
    {
        id: 16,
        groupId: 2,
        name: 'Funkcja CORNER',
        shortDescription: '- funkcja doświetlania zakrętów',
        description: `
        Funkcja doświetlania zakrętów pozwala kierowcy wcześniej dostrzec przeszkodę podczas skręcania lub parkowania.
        Gdy kierowca włącza kierunkowskaz lub wykonuje ruch kierownicą, automatycznie uruchamia światło przeciwmgielne
        po stronie, w\u00A0którą skręca.`,
        videoUrl: window.location.origin + '/video/corner_function.mp4',
        imgUrl: './assets/images/safety-systems/corner_function.jpg'
    }
];
