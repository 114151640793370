import { Component, ViewChild, HostListener } from '@angular/core';

@Component({
    selector: 'app-modele',
    templateUrl: './modele.component.html',
    styleUrls: ['./modele.component.scss']
})
export class ModeleComponent {
    showMobile = false;
    isMobileDevice = false;
    screenWidth = window.innerWidth;
    contentBtnMobile = 'Zobacz więcej modeli';

    numberSlideShow = 2;
    slides = [
        {
            model: 'NOWA ŠKODA OCTAVIA',
            img: './assets/images/models/octavia.jpg',
            content: 'Bestsellerowy model w nowej, jeszcze bardziej stylowej i przestronnej wersji. Oferuje imponujący komfort dla wszystkich pasażerów, innowacyjne systemy bezpieczeństwa oraz urzekający design łączący dynamikę z elegancją.',
            equipment: [
                'COLLISION AVOIDANCE ASSIST',
                'CZUJNIKI PARKOWANIA',
                'DRIVER ALERT',
                'FRONT ASSIST',
                'HILL HOLD CONTROL',
                'LANE ASSIST',
                'LIGHT AND RAIN ASSIST',
                'MANOEUVRE ASSIST',
                'MULTICOLLISION BRAKE',
                'POŁĄCZENIE ALARMOWE',
                'PRZEDNIE ŚWIATŁA PRZECIWMGŁOWE LED Z FUNKCJĄ CORNER'
            ],
            ctaHref: 'https://premieraroku.pl/',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/8cc3a6bc-24f9-4298-b6f7-7f92722584fa/cennik_nowa_octavia_rok_modelowy_2021.e9a028008c85db7203145063b69f83d6.pdf'
        },
        {
            model: 'NOWA ŠKODA SUPERB',
            img: './assets/images/models/superb.jpg',
            content: 'Nowa definicja luksusu i elegancji. To flagowy model marki, który został dopracowany do perfekcji pod każdym względem. Oferuje maksymalny komfort, najwyższe bezpieczeństwo i zaawanasowaną technologię dla wszystkich.',
            equipment: [
                'HILL HOLD CONTROL',
                'FRONT ASSIST',
                'PEDESTRIAN MONITOR',
                'DRIVER ALERT',
                'MULTICOLLISION BRAKE',
                'MANOEUVRE ASSIST',
                'LIGHT ASSIST'
            ],
            ctaHref: 'https://www.skoda-auto.pl/modele/nowy-superb?_ga=2.209586642.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/4188fd29-4fcf-495c-a82c-445be915e3aa/cennik_superb_rok_modelowy_2021.d0872baa26d455ba5baf46147c484aff.pdf'
        },
        {
            model: 'ŠKODA KAMIQ ',
            img: './assets/images/models/kamiq.jpg',
            content: 'Miejski SUV z nowej perspektywy. Jest przemyślaną kombinacją stylu z codzienną praktycznością, a dodatkowo zaskakuje nowoczesną technologią. Tworzy własne trendy, zamiast za nimi podążać.',
            equipment: [
                'DRIVER ALERT',
                'EASY LIGHT ASSIST',
                'FRONT ASSIST',
                'HILL HOLD CONTROL',
                'LANE ASSIST',
                'MULTICOLLISION BRAKE',
                'POŁĄCZENIA ALARMOWE'
            ],
            ctaHref: 'https://www.skoda-auto.pl/modele/kamiq?_ga=2.209586642.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/03347527-e9f6-4239-a3f0-cf6a1550ef0c/cennik_kamiq_rok_modelowy_2021.c27108f2228f9f01db0bad54c8ed766b.pdf'
        },
        {
            model: 'ŠKODA KAROQ',
            img: './assets/images/models/karoq.jpg',
            content: 'SUV pełen charakteru, stylu i nowoczesnej technologii. Jego zaawansowane systemy wspierające kierowcę sprawią, że pewnie odkryjesz nim wiele nowych dróg.',
            equipment: [
                'CZUJNIKI PARKOWANIA Z TYŁU',
                'DRIVER ALERT',
                'FRONT ASSIST',
                'HILL HOLD CONTROL',
                'LIGHT ASSIST',
                'MANOEUVRE ASSIST',
                'MULTICOLLISION BRAKE',
                'PEDESTRIAN MONITOR'
            ],
            ctaHref: 'http://www.skoda-auto.pl/modele/karoq?_ga=2.209586642.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/ddf4b2b6-7e90-4d0d-be23-9044b8db60e0/cennik_karoq_rok_modelowy_2021.3d106cffa9e713bfda59e2eecdaef431.pdf'
        },
        {
            model: 'ŠKODA KODIAQ',
            img: './assets/images/models/kodiaq.jpg',
            content: 'Największy SUV w rodzinie marki, wyróżniający się najwyższym poziomem bezpieczeństwa i technologii. Imponuje nowoczesnymi rozwiązaniami i wszechstronnością na każdej drodze.',
            equipment: [
                'CZUJNIKI PARKOWANIA TYŁ',
                'DRIVER ALERT',
                'FRONT ASSIST',
                'LIGHT ASSIST',
                'MANOEUVRE ASSIST',
                'PEDESTRIAN MONITOR'
            ],
            ctaHref: 'http://www.skoda-auto.pl/modele/kodiaq?_ga=2.41708770.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/4839cf09-2877-443c-8536-02e71438239c/cennik_kodiaq_rok_modelowy_2021.870b2438ec947fc0610c157033376eb9.pdf'
        },
        {
            model: 'ŠKODA SCALA',
            img: './assets/images/models/scala.jpg',
            content: 'Kompaktowa SCALA prezentuje połączenie nowoczesności ze sportowym stylem. Jest pełna młodzieńczej energii i nowoczesnej technologii, a jednocześnie rodzinnej praktyczności. Potrafi uwieść swoją perfekcyjnością.',
            equipment: [
                'DRIVER ALERT',
                'EASY LIGHT ASSIST',
                'FRONT ASSIST',
                'HILL HOLD CONTROL',
                'LANE ASSIST',
                'MULTICOLLISION BRAKE',
                'POŁĄCZENIA ALARMOWE'
            ],
            ctaHref: 'http://scala.skoda-auto.pl/?_ga=2.41708770.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/f9f06c1b-c347-43d3-88ce-0acdba1c3625/cennik_scala_rok_modelowy_2021.9b2aff0fc83dfe1550f7b798ae69ec74.pdf'
        },
        {
            model: 'ŠKODA FABIA ',
            img: './assets/images/models/fabia.jpg',
            content: 'Bardziej wyrazista, bardziej nowoczesna, bardziej wszechstronna – nowa FABIA podnosi poprzeczkę jeszcze wyżej, przyciągając kierowców nie tylko praktycznością, ale również designem. Dodatkowo oferuje jeden z największych bagażników w swoim segmencie!',
            equipment: [
                'EASY LIGHT ASSIST',
                'HILL HOLD CONTROL',
                'MULTICOLLISION BRAKE'
            ],
            ctaHref: 'http://www.skoda-auto.pl/modele/fabia?_ga=2.41708770.1528512072.1594991364-448693203.1594036616',
            priceHref: 'https://az749841.vo.msecnd.net/sitesplpl/alv1/b9aa1f7e-dee5-4ed2-be65-b8331812ab2b/cennik_fabia_rok_modelowy_2021.12da9291e489884102aa4af972173684.pdf'
        },
    ];
    slideConfig = {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        adaptiveHeight: true
    };

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.checkWidth();
    }

    checkWidth() {
        this.isMobileDevice = this.screenWidth < 993;
    }

    mobileArrayLenght() {
        const arrayLenght = this.slides.length;
        if (!this.showMobile) {
            this.numberSlideShow = arrayLenght;
            this.contentBtnMobile = 'zwiń';
        } else {
            this.numberSlideShow = 2;
            this.contentBtnMobile = 'Zobacz więcej modeli';
        }
    }
}
