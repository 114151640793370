import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Power} from "./power.model";
import {powerSection} from "./power";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/app.service';

@Component({
    selector: 'app-power-of-small-things',
    templateUrl: './power-of-small-things.component.html',
    styleUrls: ['./power-of-small-things.component.scss']
})
export class PowerOfSmallThingsComponent implements OnInit {
    powerData: Power[] = powerSection;
    videoOpen: boolean = false;
    embedLink: string;
    @ViewChild('video') video: ElementRef;

    constructor(
        private modalService: NgbModal,
        private service: AppService
        ) {}

    ngOnInit(): void {
        this.service.setisRoadShow(false);
    }

    openVideo(): void {
        this.videoOpen = true;
    }

    closeVideo(): void {
        const vid = this.video.nativeElement.src
        this.video.nativeElement.src = vid;
        this.videoOpen = false;
    }

}
