import { SystemGroup } from './system-group.model';

export const systemGroups: SystemGroup[] = [
    {
        id: 0,
        title: 'Systemy bezpieczeństwa wspomagające kierowcę',
        items: []
    },
    {
        id: 1,
        title: 'Systemy ułatwiające parkowanie',
        items: []
    },
    {
        id: 2,
        title: 'Oświetlenie/widoczność',
        items: []
    }
];
