<section id="experience">
  <h2>Nasze doświadczenie</h2>
  <div class="experience-items">
    <div class="item">
      <img src="./assets/images/experience/box-1-photo.png" alt="">
      <div class="item-content">
        <h3>ŠKODA Auto Lab</h3>
        <p>Już 250 tysięcy osób poznało zasady bezpiecznej&nbsp;jazdy&nbsp;– w&nbsp;teorii i praktyce.</p>
        <a href="/nasze-doswiadczenie/skoda-auto-lab" >
          <button class="btn-green">Czytaj więcej</button>
        </a>

      </div>
    </div>

    <div class="item">
      <img src="./assets/images/experience/box-2-photo.png" alt="">
      <div class="item-content">
        <h3>Trenerzy ŠKODA Auto Safety</h3>
        <p>Przekazujemy Ci nasze doświadczenie, żebyś&nbsp;Ty&nbsp;cieszył się&nbsp;bezpieczeństwem.</p>
        <a href="/nasze-doswiadczenie/trenerzy-skoda-auto-safety" >
          <button class="btn-green">Czytaj więcej</button>
        </a>

      </div>
    </div>

    <div class="item">
      <img src="./assets/images/experience/box-3-photo.png" alt="">
      <div class="item-content">
        <h3>ŠKODA Auto Szkoła</h3>
        <p>Najdłużej działająca i najbardziej utytułowana&nbsp;szkoła bezpiecznej jazdy.</p>
        <a href="/nasze-doswiadczenie/skoda-auto-szkola" >
          <button class="btn-green">Czytaj więcej</button>
        </a>

      </div>
    </div>
  </div>
</section>
