import { AppService } from './../app.service';
import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { carArray } from '../cars';
import { Car } from '../car.model';
import { AlertMessageService } from '../alert-message/alert-message.service';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
    @Input() formKey: string;

    form: FormGroup;
    electronic = false;
    calls = false;
    email = false;
    isSubmitted = false;
    isMoreInfoOpened = false;
    modelsToSelect = carArray;
    selectedModel: Car;

    set dropdownCar(model: Car) {
        if (this.form !== undefined) {
            this.selectedModel = model;
        }
    }
    private formData = new FormData();
    private isBlocked = false;

    constructor(
        private alertService: AlertMessageService,
        private elem: ElementRef,
        private fb: FormBuilder,
        private http: HttpClient,
        private appService: AppService
    ) { }

    handleModelChange(newModel: Car) {
        this.selectedModel = newModel;
        this.appService.replaceUrl('offerForm', this.selectedModel.key);
    }

    fontBold(el) {
        if (el === 'electronic') {
            this.electronic = !this.electronic;
        }

        if (el === 'calls') {
            this.calls = !this.calls;
        }

        if (el === 'email') {
            this.email = !this.email;
        }
    }

    ngOnInit() {
        this.initForm();
        this.appendFieldsToFormData();
        this.selectedModel = this.modelsToSelect[0];
    }

    sendForm() {
        const settings = this.setFormSendSettings();
        this.checkApprovalsValues();
        this.isSubmitted = true;

        if (this.form.valid && !this.isBlocked) {
            this.mapApprovalsValues();
            this.setValuesOfFormData();

            this.http.post<{ externalId: string, status: string }>(settings.apiUrl, this.formData).subscribe(
                response => {
                    this.alertService.setAlert('default');
                    console.log('wysłano');
                    this.trackFormSendingSuccess(response.externalId, settings.formNameGA, settings.formNameSynerise);
                    this.resetForm();
                },
                error => {
                    this.alertService.setAlert('error');
                    console.log('błąd');
                }
            );
        } else {
            const invalidFields = this.elem.nativeElement.querySelectorAll('.ng-invalid');
        }
    }

    private appendFieldsToFormData() {
        const fieldsToAppend = ['carModel'];
        Object.keys(this.form.value).forEach(key => fieldsToAppend.push(key));
        fieldsToAppend.forEach(field => this.formData.append(field, ''));
    }

    private checkApprovalsValues() {
        if (
            (this.form.value['gdpr-answer-marketing-messages-importer'] &&
                !(
                    this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls']
                )) ||
            (!this.form.value['gdpr-answer-marketing-messages-importer'] &&
                (this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls']))
        ) {
            this.isBlocked = true;
        } else {
            this.isBlocked = false;
        }
    }

    private initForm() {
        this.form = this.fb.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')
                ],
            ],
            phoneNo: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{9}$')]],
            email: ['', [Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]],
            'gdpr-answer-marketing-messages-importer': false,
            'gdpr-answer-email': false,
            'gdpr-answer-electronic-messages': false,
            'gdpr-answer-voice-calls': false,
        });
    }

    private mapApprovalsValues() {
        this.form.value['gdpr-answer-marketing-messages-importer'] = this.form.value['gdpr-answer-marketing-messages-importer'] ? 1 : 0;
        this.form.value['gdpr-answer-email'] = this.form.value['gdpr-answer-email'] ? 1 : 0;
        this.form.value['gdpr-answer-electronic-messages'] = this.form.value['gdpr-answer-electronic-messages'] ? 1 : 0;
        this.form.value['gdpr-answer-voice-calls'] = this.form.value['gdpr-answer-voice-calls'] ? 1 : 0;
    }

    private resetForm() {
        this.isBlocked = false;
        this.isSubmitted = false;
        this.form.reset();
    }

    private setValuesOfFormData() {
        this.formData.set('carModel', this.selectedModel.key);
        Object.keys(this.form.value).forEach(x => {
            this.formData.set(x, this.form.value[x]);
        });
    }

    private setFormSendSettings() {
        if (this.formKey === 'offer') {
            return {
                apiUrl: 'https://fbods2.kampanie.skoda.pl/web/handler/SKSAFETY2020?key=er23riru8wefewfiewfief34mi43egergerge',
                formNameSynerise: `${this.selectedModel.key},oferta,lp.safety`,
                formNameGA: 'oferta-202007-skoda-safety'
            };
        } else {
            return {
                apiUrl: 'https://fbods2.kampanie.skoda.pl/web/handler/SKSAFETY2020?key=er23riru8wefewfiewfief34mi43egergerge',
                formNameSynerise: `${this.selectedModel.key},jazda.probna,lp.safety`,
                formNameGA: 'jazda-probna-zamowiono-202007-skoda-safety'
            };
        }


    }

    private trackFormSendingSuccess(orderId: string, formNameGA: string, formNameSynerise: string) {
        let dataLayer = window['dataLayer'];
        dataLayer.push({
            event: 'formularze',
            'wypelniony-formularz': formNameGA,
            orderid: orderId,
            model: this.selectedModel.key
        });

        if (this.formKey !== 'offer') {
            dataLayer = window['dataLayer'];
            dataLayer.push({
                event: 'ga_event',
                eventCategory: 'formularze',
                eventAction: 'wypełniony formularz',
                eventLabel: 'jazda-próbna-zamowiono-202108-Safety'
            });
        }

        if (window['SyneriseTC'] !== undefined) {
            window['SyneriseTC'].sendFormData(
                formNameSynerise,
                {
                    firstname: this.form.controls.name.value,
                    phone: this.form.controls.phoneNo.value,
                    email: this.form.controls.email.value,
                    newsletterAgreement: this.form.controls['gdpr-answer-email'].value ? 'enabled' : 'disabled',
                    receive_smses: this.form.controls['gdpr-answer-electronic-messages'].value ? 'true' : 'false',
                    phonecall: this.form.controls['gdpr-answer-voice-calls'].value ? 'yes' : 'no',
                    orderID: orderId
                }
            );
        }
    }

}
