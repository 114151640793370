import { Component, Input } from '@angular/core';

import { AppService } from './../../app.service';

@Component({
    selector: 'app-modele-item',
    templateUrl: './modele-item.component.html',
    styleUrls: ['./modele-item.component.scss']
})
export class ModeleItemComponent {
@Input() item: any;

constructor(private appService: AppService) { }

    equipment = false;

    trackSyneriseEvent(eventName: string, modelName?: string) {
      this.appService.trackSyneriseEvent(eventName, modelName);
  }
}
