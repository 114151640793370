import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html'
})
export class ErrorMsgComponent  {
  @Input() control: FormControl;
}
