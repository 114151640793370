import {Items, Power} from "./power.model";

export const powerSection: Power[] = [
    {
        id: 0,
        title: `Luźno rzucone drobiazgi w Twoim aucie mogą się stać realnym zagrożeniem podczas gwałtownego hamowania lub zderzenia. Nie wierzysz?`,
        titleGreen: true,
        sections: [
            {
                id: 0,
                title: 'Hamowanie',
                description: `Podczas gwałtownego hamowania niezabezpieczone przedmioty mogą wpaść pod fotel lub… prosto pod Twoje nogi. To bardzo niebezpieczna sytuacja, ponieważ taki „drobiazg” może wsunąć się np. pod pedał hamowania.`,
                // buttonUrl: window.location.origin + '/video/side_assist.mp4',
                imgUrl: './assets/images/power-of-small-things/video1.png',
                videoUrl: 'https://www.youtube.com/watch?v=UJLuOM3u-rY&autoplay=1',
                videoBreak: true
            },
            {
                id: 1,
                title: 'Dachowanie',
                description: `Systemy bezpieczeństwa SKODY są w stanie uchronić Cię przed wieloma negatywnymi skutkami wypadku. Jednak przedmioty pozostawione luzem nadal mogą Cię skrzywdzić. Pamiętaj, aby drobiazgi zamykać w schowku, a torebkę chować np. do bagażnika.`,
                // buttonUrl: window.location.origin + '/video/side_assist.mp4',
                imgUrl: './assets/images/power-of-small-things/photo1.png',
                videoUrl: 'https://www.youtube.com/watch?v=h90GwMcepbY&autoplay=1',
                videoBreak: false
            },
        ]
    },
    {
        id: 1,
        title: 'Poznaj pozostałe akcje #ROKBEZPIECZENSTWA Škody',
        titleGreen: false,
        sections: [
            // {
            //     id: 0,
            //     title: 'Bezpieczny wózek',
            //     description: `Wózek to pierwsze cztery kółka każdego człowieka. Dlatego przełożyliśmy do niego systemy bezpieczeństwa SKODY. Na razie na próbę. Sprawdź, jak działają w naszym prototypowym modelu`,
            //     buttonUrl: window.location.origin + '/video/side_assist.mp4',
            //     imgUrl: './assets/images/power-of-small-things/VIDEO-V1_30s.png',
            // },
            {
                id: 1,
                title: 'Dzielimy razem drogę',
                description: `Jeździsz samochodem? A może czasem przesiadasz się na rower? Droga jest miejscem spotkania nas wszystkich – kierowców i rowerzystów. Zobacz, jak poprawić nasze wspólne bezpieczeństwo podczas podróży.`,
                buttonUrl: 'https://safety.skoda-auto.pl/dzielimyrazemdroge',
                imgUrl: './assets/images/power-of-small-things/053A9886.png',
            },
        ]
    },

];
export const itemsList: Items[] = [
    {
        id: 0,
        imgUrl: './assets/images/power-of-small-things/Image5.png',
        weight: 0.8
    },
    {
        id: 1,
        imgUrl: "./assets/images/power-of-small-things/Image7.png",
        weight: 0.3
    },
    {
        id: 2,
        imgUrl: "./assets/images/power-of-small-things/Image8.png",
        weight: 0.15
    },
    {
        id: 3,
        imgUrl: "./assets/images/power-of-small-things/Image9.png",
        weight: 0.6
    },
    {
        id: 4,
        imgUrl: "./assets/images/power-of-small-things/Image4.png",
        weight: 0.1
    },
    {
        id: 5,
        imgUrl: "./assets/images/power-of-small-things/Image6.png",
        weight: 0.4
    },
    {
        id: 6,
        imgUrl: "./assets/images/power-of-small-things/Image3.png",
        weight: 0.2
    },
    {
        id: 7,
        imgUrl: "./assets/images/power-of-small-things/Image2.png",
        weight: 1.55
    },
    {
        id: 8,
        imgUrl: "./assets/images/power-of-small-things/shadow2.svg",
    },
    {
        id: 9,
        imgUrl: "./assets/images/power-of-small-things/shadow1.svg",
    },

];
