import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  private alertType$ = new Subject<string>();

  constructor() { }

  setAlert(alertType: string) {
    this.alertType$.next(alertType);
  }

  getAlert(): Observable<string> {
    return this.alertType$.asObservable();
  }
}
