<header>
    <nav class="navbar fixed-top navbar-expand-lg">
        <a class="navbar-brand" href="https://safety.skoda-auto.pl/" target="_blank">
            <img class="w-100 mw-100" src="./assets/images/logo.png" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div #toggler class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item" *ngIf="!isShowPartMenu">
                    <a class="nav-link" routerLink="/safety" (click)=" scrollToSection('safety', 200)">
                        <img class="menu-icon" src="./assets/images/icons/safety-icon.svg" />
                        <span>Systemy Bezpieczeństwa</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/safety" (click)=" scrollToSection('models', 200)">
                        <img class="menu-icon" src="./assets/images/icons/model-icon.svg " />
                        <span>Modele</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isShowPartMenu">
                    <a class="nav-link" routerLink="/safety" (click)=" scrollToSection('experience', 200)">
                        <img class="menu-icon" src="./assets/images/icons/exp-icon.svg" />
                        <span>Nasze doświadczenie</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/safety"
                       (click)=" hideMobileNav(); scrollToSection('form', 200); trackSyneriseEvent('jazda.probna'); gaFromQuiz('TopMenu_Click_Jazda_Próbna', '202108-Safety'); pushDataLayer('Button_Click_Jazda_Próbna', '', '')">
                        <img class="menu-icon" src="./assets/images/icons/test-icon.svg" />
                        <span>Jazda próbna</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isShowPartMenu">
                    <a class="nav-link" routerLink="dzielimyrazemdroge" (click)=" hideMobileNav()">
                        <img class="menu-icon hash" src="./assets/images/icons/hashtag-icon.svg" />
                        <span>ROK BEZPIECZEŃSTWA</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.skoda-auto.pl/inne/oferta-safety" target="_blank"
                       (click)=" hideMobileNav(); trackSyneriseEvent('oferta'); gaFromQuiz('TopMenu_Click_Zapytaj-o-oferte', '202108-Safety'); pushDataLayer('Button_Click_Zapytaj-o-oferte', '', '')">
                        <img class="menu-icon" src="./assets/images/icons/price-icon.svg" />
                        <span>Zapytaj o&nbsp;ofertę</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isShowPartMenu">
                    <a class="nav-link" href="https://cc.skoda-auto.com/pol/pl-PL/" target="_blank"
                       (click)=" hideMobileNav(); trackSyneriseEvent('cc'); gaFromQuiz('TopMenu_Click_Konfigurator', '202107-Safety'); pushDataLayer('Button_Click_Konfigurator', '202107-Safety', '') ">
                        <img class="menu-icon" src="./assets/images/icons/config-icon.svg" />
                        <span>Konfigurator</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    <div class="block-menu"></div>
</header>
