import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../app.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    footerShowSubscription: Subscription;
    roadShowSubscription: Subscription;
    constructor(private appService: AppService) { }

    isShow;
    showLegal;

    ngOnInit() {
        this.footerShowSubscription = this.appService.getIsFooterShow().subscribe((data) => this.isShow = data);

        this.roadShowSubscription = this.appService.getisRoadShow().subscribe((data) => this.showLegal = data);
    }


    ngOnDestroy() {
        this.footerShowSubscription.unsubscribe;
        this.roadShowSubscription.unsubscribe;
    }
}
