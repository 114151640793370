<footer *ngIf="isShow">
    <div class="container-content">
        <div class="social-icons">
            <a target="_blank" href="https://www.facebook.com/skodapolska/?fref=ts " title="ŠKODA Polska na facebooku"
                class="ico-fb">
                <img src="./assets/images/footer-icons/fb.svg">
            </a>
            <a target="_blank" href="https://www.youtube.com/user/SkodaAutoPolska " title="ŠKODA Polska na youtubie"
                class="ico-yt">
                <img src="./assets/images/footer-icons/ico-yt.svg">
            </a>
            <a target="_blank" href="https://www.instagram.com/skodapolska/" title="ŠKODA Polska na instagramie"
                class="ico-ig">
                <img src="./assets/images/footer-icons/ico-ig.svg">
            </a>
        </div>

        <hr>

        <div class="footer-info">
            <p class="date">©2020 ŠKODA Auto </p>

            <div>
                <p class="wltp">Sprawdź informacje na temt WLTP na stronie
                    <a href="https://www.skoda-auto.pl/swiat-skody/wltp" target="_blank">
                        www.skoda-wltp.pl
                    </a>
                </p>
                <p>
                    <a href="http://www.skoda-auto.pl/inne/polityka-plikow-cookies" target="_blank">
                        PLIKI COOKIES
                    </a>
                </p>
                <p>
                    <a href="https://www.skoda-auto.pl/sitemap" target="_blank">
                        MAPA STRONY
                    </a>
                </p>
            </div>
        </div>
        <p class="footer-legal" *ngIf="showLegal">
            ŠKODA realizuje politykę ciągłego rozwoju produktów i&nbsp;zastrzega sobie prawo wprowadzania zmian w zakresie
            parametrów technicznych, specyfikacji, kolorów i&nbsp;cen bez wcześniejszego powiadomienia.
            Wszelkie informacje zawarte w niniejszej broszurze należy traktować wyłącznie orientacyjnie, gdyż nie
            stanowią oferty w rozumieniu Kodeksu cywilnego oraz nie są wiążące.
            Prezentowane informacje nie stanowią zapewnienia w rozumieniu art. 556<sup>1</sup>§2 Kodeksu cywilnego.
            Firma ŠKODA dokłada wszelkich starań, aby informacje i&nbsp;dane techniczne zawarte w niniejszej broszurze były
            zgodne ze stanem faktycznym na dzień publikacji, jednak zastrzega sobie prawo do zmian i&nbsp;pomyłek.
            <br><br>

            W&nbsp;celu uzyskania najnowszych informacji prosimy kontaktować się z&nbsp;Autoryzowanym Dealerem marki ŠKODA.
            Przedstawione detale wyposażenia mogą różnić się od specyfikacji przewidzianej na rynek polski. Zamieszczone
            zdjęcia mogą przedstawiać wyposażenie opcjonalne. Wiążące ustalenie ceny, wyposażenia i&nbsp;specyfikacji pojazdu
            następuje w umowie sprzedaży, a określenie parametrów technicznych zawiera świadectwo homologacji typu
            pojazdu.
            Z&nbsp;uwagi na ograniczenia technik drukarskich, kolory przedstawione w niniejszej broszurze mogą nieznacznie
            różnić się od faktycznych kolorów lakieru i&nbsp;materiałów.
            <br><br>

            Wszystkie produkowane obecnie samochody marki ŠKODA są wykonywane z&nbsp;materiałów spełniających pod względem
            możliwości odzysku i&nbsp;recyklingu wymagania określone w normie ISO 22628 i&nbsp;są zgodne z&nbsp;europejskimi
            świadectwami homologacji wydanymi wg dyrektywy 2005/64/WE.
            Volkswagen Group Polska Sp. z&nbsp;o.o. podlega obowiązkowi zapewnienia wszystkim użytkownikom samochodów marki
            ŠKODA sieci odbioru pojazdów po wycofaniu ich z&nbsp;eksploatacji, zgodnie z&nbsp;wymaganiami ustawy z&nbsp;20 stycznia
            2005 r. o&nbsp;recyklingu pojazdów wycofanych z&nbsp;eksploatacji (t.j. D z.U. z&nbsp;2018 r., poz. 578). Więcej informacji
            dotyczących ekologii znajdą Państw o&nbsp;na stronach: <a href="http://www.skoda-wltp.pl" target="_blank"
                rel="noopener ">www.skoda-wltp.pl</a>
            <br><br>

            Systemy bezpieczeństwa działają wyłącznie w ramach ich technologicznych granic i&nbsp;nadal niezbędne jest
            zachowanie należytej ostrożności przez kierowcę. Kierowca musi być w każdej chwili gotowy do przejęcia
            kontroli nad pojazdem. Systemy wspomagające nie zwalniają go z&nbsp;odpowiedzialności za zachowanie szczególnej
            ostrożności.
            <br><br>

            Od 1 września 2018 r. wszystkie nowe pojazdy wprowadzane do obrotu w Unii Europejskiej muszą być badane i&nbsp;homologowane zgodnie z&nbsp;procedurą WLTP określoną w rozporządzeniu Komisji (UE) 2017/1151. WLTP zapewnia
            bardziej rygorystyczne warunki badania i&nbsp;bardziej realistyczne wartości zużycia paliwa i&nbsp;emisji
            CO<sub>2</sub> w porównaniu do stosowanej to tej pory metody NEDC. Montaż akcesoriów w pojeździe może mieć
            wpływ na poziom zużycia paliwa i&nbsp;emisji CO<sub>2</sub> oraz może nastąpić najwcześniej po pierwszej
            rejestracji pojazdu, wyłącznie na Państwa życzenie. W zależności od wariantu i&nbsp;wersji zużycie energii
            elektrycznej od 156 do 181 Wh/km, emisja CO<sub>2</sub> 0 g/km, zasięg w trybie elektrycznym od 393 km do
            536 km (dane na podstawie świadectw homologacji typu). Zużycie energii elektrycznej i&nbsp;emisja CO<sub>2</sub>
            zostało określone zgodnie z&nbsp;procedurą WLTP. O&nbsp;szczegóły zapytaj Autoryzowanego Dealera Marki ŠKODA lub
            sprawdź na stronie: <a href="http://www.skoda-wltp.pl" target="_blank" rel="noopener ">www.skoda-wltp.pl</a>
        </p>
    </div>

</footer>
