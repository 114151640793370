import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertMessageService } from './alert-message.service';
import { Alert } from './alert.model';


@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})

export class AlertMessageComponent implements OnInit, OnDestroy {
  alerts = [
    {
      type: 'error',
      titleCopy: 'Przepraszamy.',
      messageCopy: 'Wystąpił błąd, spróbuj ponownie'
    },
    {
      type: 'phoneApprovalChecked',
      titleCopy: 'Dziękujemy za zapisanie się.',
      messageCopy: `Skontaktujemy się z&nbsp;Tobą w&nbsp;celu potwierdzenia Twoich zgód na działania marketingowe naszej
                    marki w&nbsp;przyszłości.<br />
                    Na podany numer telefonu zadzwonimy lub wyślemy SMS, z&nbsp;prośbą o&nbsp;ich
                    potwierdzenie, poprzez kliknięcie w&nbsp;przesłany link.`
    },
    {
      type: 'emailApprovalChecked',
      titleCopy: 'Dziękujemy za zapisanie się.',
      messageCopy: `Skontaktujemy się z&nbsp;Tobą w&nbsp;celu potwierdzenia Twoich zgód na działania marketingowe
                    naszej marki w&nbsp;przyszłości.<br />
                    Na podany adres e-mail wyślemy Ci prośbę o&nbsp;ich potwierdzenie,
                    poprzez kliknięcie w link.`
    },
    {
      type: 'emailAndPhoneApprovalChecked',
      titleCopy: 'Dziękujemy za zapisanie się.',
      messageCopy: `Skontaktujemy się z&nbsp;Tobą w&nbsp;celu potwierdzenia Twoich zgód na działania marketingowe
                    naszej marki w&nbsp;przyszłości.<br />
                    Na podany adres e-mail lub na podany numer telefonu
                    zadzwonimy/wyślemy SMS, z&nbsp;prośbą o&nbsp;potwierdzenie zgód poprzez kliknięcie w&nbsp;przesłany link.`
    },
    {
      type: 'default',
      titleCopy: 'Dziękujemy za zapisanie się.',
      messageCopy: `Twoje zgłoszenie zostało pomyślnie przesłane.`
    }
  ];
  currentAlert: Alert = null;

  private alertSubscription: Subscription;

  constructor(private alertService: AlertMessageService) { }

  ngOnInit() {
    this.alertSubscription = this.alertService.getAlert().subscribe(alertType => {
      const invokedAlert = this.alerts.find(el => el.type === alertType);
      this.currentAlert = invokedAlert !== undefined ? this.currentAlert = invokedAlert : null;
    });
  }

  closeAlert() {
    this.currentAlert = null;
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }
}
