<div class="item-car ">
  <div class="picture-area">
      <img [src]="item.img" [alt]="item.model">
    <div class="equipment-box" [class.show-box]="equipment">
      <div class="equipment-item" [class.show-item]="equipment">
        <h4>Systemy w standardzie wyposażenia:</h4>
        <ul >
          <li *ngFor="let el of item.equipment">{{ el }}</li>
        </ul>
      </div>
      <div class="more-info" (click)="equipment = !equipment; ">
        <p>{{ equipment ? 'zamknij' : 'więcej' }}</p>
        <img src="./assets/images/icons/plus-icon.svg" alt="" [class.more-info__active]="equipment">
      </div>
    </div>
    </div>
  <div class="item-content">
    <h3>{{ item.model }}</h3>
    <p>{{ item.content }}</p>
    <div class="item-car-btn">
      <a [href]="item.ctaHref" target="_blank" [title]="item.model">
        <button class="btn-green ">Poznaj Model</button>
      </a>
      <a href="https://twoja.skoda.pl/skoda-test-offer2017/ver4/" target="_blank" title="zapytaj o ofertę" (click)="trackSyneriseEvent('oferta', item.model)">
        <button class="btn btn-green">Zapytaj o ofertę</button>
      </a>
    </div>
  </div>
</div>
