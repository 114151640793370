
<div class="group-block" [class.active]="isToggled">
    <div class="group-block-header container-padding-x" (click)="isToggled = !isToggled">
        <div>0{{ systemGroup.id + 1 }}</div>
        <div>{{ systemGroup.title }}</div>
    </div>
    <div class="group-block-content">
        <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig(slickModal)"
        >
            <div
                ngxSlickItem
                class="slide"
                *ngFor="let system of systemGroup.items; let i=index"
            >
                <ng-container *ngIf="getCurrentSlideIndex() === i">
                    <video
                        class="w-100 mw-100 mh-100"
                        width="100%"
                        oncanplay="this.play()"
                        onloadedmetadata='this.muted=true'
                        playsinline loop
                    >
                        <source [src]="system.videoUrl" type="video/mp4">
                    </video>
                    <div class="container-padding-x my-5">
                        <div class="white-shadow-container position-relative">
                            <p class="font-bold mb-4">{{ system.name }}</p>
                            <p>{{ system.description }}</p>
                            <div class="group-id">0{{ systemGroup.id + 1 }}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ngx-slick-carousel>
        <div class="carousel-nav container-padding-x">
            <div class="carousel-arrows mt-0">
                <div class="arrow prev" (click)="slickModal.slickPrev()"></div>
                <div class="arrow next" (click)="slickModal.slickNext()"></div>
            </div>
        </div>
    </div>
</div>
