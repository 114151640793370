<section class="safety-systems-section" id="safety" [class.container-padding-y]="!isMobileDevice">
    <h2 class="section-title">Poznaj systemy, które dbają o Twoje bezpieczeństwo</h2>
    <ng-container *ngTemplateOutlet="isMobileDevice ? mobileView : desktopView"></ng-container>
</section>

<ng-template #desktopView>
    <div class="d-flex align-items-start">
        <div class="system-groups-container container-padding-x">
            <div class="font24 font-bold my-5">Systemy bezpieczeństwa</div>
            <div>
                <div
                    class="group-block"
                    *ngFor="let group of systemGroups"
                    [class.active]="currentGroupId === group.id"
                >
                    <div class="group-block-header" (click)="changeCurrentGroupId(group.id)">
                        <div>0{{ group.id + 1 }}</div>
                        <div>{{ group.title }}</div>
                    </div>
                    <div class="group-block-content">
                        <ul>
                            <li
                                *ngFor="let system of group.items"
                                [class.active]="currentSystemId === system.id"
                                (click)="changeCurrentSystemId(system.id, system.name)"
                            >
                                <div><span class="font-bold">{{ system.name }}</span> {{ system.shortDescription }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="system-carousel">
            <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="systemCarouselConfig"
            >
                <div ngxSlickItem *ngFor="let system of systems; let i=index" class="slide system-slide">
                    <ng-container *ngIf="currentSystemId === system.id">
                        <video
                            class="w-100 mw-100 mh-100"
                            width="100%"
                            oncanplay="this.play()"
                            onloadedmetadata='this.muted=true'
                            playsinline loop
                        >
                            <source [src]="system.videoUrl" type="video/mp4">
                        </video>
                        <p class="font-light">{{ system.description }}</p>
                    </ng-container>
                </div>
            </ngx-slick-carousel>

            <div class="carousel-nav white-shadow-container">
                <div class="group-id">0{{ currentGroupId + 1 }}</div>
                <div class="font24 font-bold">{{ systems[currentSystemId].name }}</div>
                <div class="carousel-arrows">
                    <div class="arrow prev" (click)="goToPrevSlide(systems[currentSystemId - 1].name)"></div>
                    <div class="arrow next" (click)="goToNextSlide(systems[currentSystemId + 1].name)"></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mobileView>
    <ng-container *ngFor="let group of systemGroups">
        <app-mobile-system-group [systemGroup]="group"></app-mobile-system-group>
    </ng-container>
</ng-template>
