import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SubjectSubscriber } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public currentSectionName = '';
    public isHomePage = true;
    private navSectionRef$ = new Subject<string>();
    private isFooterShow$ = new Subject<boolean>();
    private isRoadShow$ = new Subject<boolean>();

    getNavSectionRef() {
        return this.navSectionRef$.asObservable();
    }

    setNavSectionRef(sectionRef) {
        this.navSectionRef$.next(sectionRef);
    }

    getIsFooterShow() {
        return this.isFooterShow$.asObservable();
    }

    setIsFooterShow(value) {
        this.isFooterShow$.next(value);
    }

    getisRoadShow() {
        return this.isRoadShow$.asObservable();
    }

    setisRoadShow(value) {
        this.isRoadShow$.next(value);
    }

    trackGAEventClick(eventActionName: string, eventLabel?: string) {
        const dataLayer = window['dataLayer'];
        dataLayer.push(
            {
                event: 'ga_event',
                eventCategory: 'Campaign LP',
                eventAction: 'show-system-' + eventActionName,
                eventLabel: eventLabel ? eventLabel : '202009-skodasafety'
            }
        );
    }

    trackGAEventClickQuiz(eventActionName: string, eventLabelName: string, eventCategoryName?: string) {
        const dataLayer = window['dataLayer'];
        dataLayer.push(
            {
                event: 'ga_event',
                eventCategory: eventCategoryName ? eventCategoryName : 'Campaign LP',
                eventAction: eventActionName,
                eventLabel: eventLabelName
            }
        );
      }




    trackSyneriseEvent(eventName: string, model?: string) {
        if (window['SR']) {
            window['SR'].event.trackCustomEvent(eventName, {
                type: 'click',
                domain: window.location.hostname,
                model: model ? model : '',
                kampania: 'Skoda Safety',
                BP: 'false'
            }, eventName);
        }
    }

    replaceQueryParam(sectionName: string, modelKey: string) {
        let queryParam = '';
        if (location.search === '') {
            queryParam = `?section=${sectionName}&model=${modelKey}`;
        } else if (location.search.indexOf('section') === -1 && location.search.indexOf('model') === -1) {
            queryParam = `${location.search}&section=${sectionName}&model=${modelKey}`;
        } else {
            const indexOfSectionQueryLeft = location.search.indexOf('section');
            const indexOfModelQueryLeft = location.search.indexOf('model');
            const indexOfModelQueryRight = location.search.indexOf('&', indexOfModelQueryLeft);
            const leftStr = location.search.substr(0, indexOfSectionQueryLeft);
            queryParam = leftStr;
            queryParam += `section=${sectionName}&model=${modelKey}`;
            if (indexOfModelQueryRight !== -1) {
                queryParam += location.search.substr(indexOfModelQueryRight);
            }
        }
        return queryParam;
    }

    replaceUrl(sectionName: string, modelKey: string) {
        const newUrl = `${location.origin}${location.pathname}${this.replaceQueryParam(sectionName, modelKey)}${location.hash}`;
        history.pushState(null, null, newUrl);
    }

    scrollToSection(sectionId, time) {
        setTimeout(() => {
            const el = document.getElementById(sectionId);
            el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, time);

    }
    trackGAEventOnPage(eventActionName: string, eventLabel?: string, eventCategory?: string) {
        const dataLayer = window['dataLayer'];
        dataLayer.push(
            {
                event: 'ga_event',
                eventCategory: eventCategory ? eventCategory : 'Campaign LP',
                eventAction: eventActionName,
                eventLabel: eventLabel ? eventLabel : '202108-Safety'
            }
        );
    }
}


