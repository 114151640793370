<section class="header">
    <div class="header-media">
        <div class="header-movie">
            <video *ngIf="!isApple" width="100%" oncanplay="this.play()" onloadedmetadata='this.muted=true' loop>
                <source src="./assets/images/header/Skoda_Safety_4x3a.mp4" type="video/mp4">
            </video>
            <video *ngIf="isApple" width="100%" muted playsinline autoplay loop>
                <source src="./assets/images/header/Skoda_Safety_4x3a.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    <div class="header-content">
        <h2>#ROKBEZPIECZEŃSTWA</h2>
        <div class="header-movie">
            <video *ngIf="!isApple" width="100%" oncanplay="this.play()" onloadedmetadata='this.muted=true' loop>
                <source src="./assets/images/header/Skoda_Safety_4x3a.mp4" type="video/mp4">
            </video>
            <video *ngIf="isApple" width="100%" muted playsinline autoplay loop>
                <source src="./assets/images/header/Skoda_Safety_4x3a.mp4" type="video/mp4">
            </video>
        </div>
        <p class="font-bold"> ŠKODA. Wszystko, co ważne.</p>
        <p>
            A najważniejsze jest bezpieczeństwo. Twoje, Twoich bliskich i wszystkich
            użytkowników drogi. Dlatego nasze samochody standardowo wyposażone są w zaawansowane systemy bezpieczeństwa.
            Dlatego szkolimy kierowców na najnowocześniejszym w kraju autodromie.
            <br> <br>
            Prowadzimy programy edukacyjne w
            AutoLabie, a kursy w naszej Auto Szkole ukończyło już kilkadziesiąt tysięcy kierowców. Od lat wspieramy też
            tych, którzy dbają o Wasze bezpieczeństwo, na przykład ratowników TOPR. Ale możemy więcej. Dlatego w tym
            roku idziemy krok dalej.
        <br><br>
        To będzie <span class="font-bold">#ROKBEZPIECZEŃSTWA. <br> <br>
                By zadbać o wszystko, co ważne.</span> <br><br>
                Przekonaj się, że&nbsp;bezpieczeństwo naprawdę się opłaca – sprawdź nasze samochody z&nbsp;nowoczesnymi systemami bezpieczeństwa w&nbsp;wyprzedażowej ofercie.
        </p>
        <br>
        <a href="https://wyprzedaz.skoda-auto.pl/" title="wyprzedaz.skoda-auto.pl" target="_blank" class="btn-green">Dowiedz się więcej</a>
    </div>
</section>
