import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AppService } from './../app.service';

import { systemGroups } from './system-groups';
import { systems } from './systems';

@Component({
    selector: 'app-safety',
    templateUrl: './safety.component.html',
    styleUrls: ['./safety.component.scss']
})
export class SafetyComponent implements OnInit {
    currentGroupId = 0;
    currentSystemId = 0;
    systemGroups = systemGroups;
    systems = systems;
    isMobileDevice = false;
    systemCarouselConfig = {
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        swipe: false,
        autoplay: false
    };

    @ViewChild('slickModal') carousel: SlickCarouselComponent;
    private screenWidth = window.innerWidth;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.checkScreenWidth();
    }

    constructor(private service: AppService) {}


    changeCurrentSystemId(id: number, name) {
        this.service.trackGAEventClick(name);
        this.currentSystemId = id;
        this.currentGroupId = this.systems[this.currentSystemId].groupId;
        this.carousel.slickGoTo(this.currentSystemId);
    }

    changeCurrentGroupId(id: number) {
        this.currentGroupId = id;
        const firstSystemOfNewGroup = this.systems.find(el => el.groupId === this.currentGroupId);
        if (firstSystemOfNewGroup) {
            this.currentSystemId = firstSystemOfNewGroup.id;
        }
        this.carousel.slickGoTo(this.currentSystemId);
    }

    goToPrevSlide(elGA) {
        this.service.trackGAEventClick(elGA);
        if (this.currentSystemId - 1 >= 0) {
            this.currentSystemId = this.currentSystemId - 1;
            this.currentGroupId = this.systems[this.currentSystemId].groupId;
        }
        this.carousel.slickGoTo(this.currentSystemId);
    }

    goToNextSlide(elGA) {
        this.service.trackGAEventClick(elGA);
        if (this.currentSystemId + 1 <=  this.systems.length - 1) {
            this.currentSystemId = this.currentSystemId + 1;
            this.currentGroupId = this.systems[this.currentSystemId].groupId;
        }
        this.carousel.slickGoTo(this.currentSystemId);
    }

    ngOnInit() {
        this.systemGroups.forEach(group => this.setSystemGroupItems(group.id));
        this.checkScreenWidth();
    }

    private setSystemGroupItems(groupId: number) {
        const groupSystems = this.systems.filter(el => el.groupId === groupId);
        const groupToSet = this.systemGroups.find(el => el.id === groupId);
        if (groupToSet) {
            groupToSet.items = groupSystems;
        }
    }

    private checkScreenWidth() {
        this.isMobileDevice = this.screenWidth <= 1200;
    }
}
