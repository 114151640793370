import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    isApple = false;

  constructor() { }

  ngOnInit() {
    const str = navigator.userAgent;
    const patt = /Macinhosh/ig;
    const result = patt.test(str);

    const str2 =  navigator.vendor;
    const patt2 = /Apple/ig;
    const result2 = patt2.test(str2);

    (result || result2) ?  this.isApple = true :  this.isApple = false;
  }

}
