<div class="content">
    <header>
        <div class="logo">
            <img src="./assets/images/power-of-small-things/logo.png" alt="">
        </div>
        <div class="heading">
            <h1>Jaka jest siła<br/><span class="bold">drobiazgów?</span></h1>
        </div>
        <div class="description">
            <p class="text">Sprawdź wagę przedmiotów podczas zderzenia z prędkością 50km/h<br/> <span
                class="text-bold">i przekonaj się jaka może być siła drobiazgów.</span></p>
        </div>
    </header>
    <app-speed></app-speed>
    <section class="video-section">
        <div class="title">
            <h2 class="section-title">Twoje „drobiazgi” podczas zderzenia już przy 50km/h znacząco zwiększają swoją
                wagę.<br/>
                <span class="font-green">Zabezpieczaj je.</span></h2>
        </div>
        <div class="clickable-video" [ngClass]="{'open' : videoOpen}">
            <div class="video">
                <iframe #video height="100%" style="overflow:hidden;height:100%;width:100%" width="100%"
                        src="https://www.youtube.com/embed/FSLS_6V1fB8" frameborder="0"></iframe>
                <button class="btn " (click)="closeVideo()"><img class='icon'
                                                                 src="./assets/images/power-of-small-things/close.svg"
                                                                 alt="">Zamknij
                </button>
            </div>
            <button class="btn-green" (click)="openVideo()"><img class="icon"
                                                                 src="./assets/images/power-of-small-things/icon.svg"
                                                                 alt="">Zobacz wideo
            </button>
        </div>
    </section>
    <image-section *ngFor="let power of powerData" [data]="power">
    </image-section>
    <div class="container container-opinion">
        <img src="./assets/images/power-of-small-things/85skoda_photo_pp_pp.png" alt="" class="src">
        <div class="divider"></div>
        <div class="text-container">
            <p class="text-dark text-dark-padd">„Wsiadając do samochodu, odruchowo rzucamy torebkę, telefon czy portfel
                na przedni
                fotel pasażera. Bo tak jest po prostu szybciej. Jednak musimy zacząć zwracać uwagę nie tylko na naszą
                wygodę, ale również bezpieczeństwo podczas każdej, nawet najkrótszej podróży. Chowajmy drobiazgi, by nas
                nie skrzywdziły”.</p>
            <p class="text-dark"><span>Filip Kaczanowski,</span> trener ŠKODA Auto Safety</p>
        </div>
    </div>
</div>
<div class="extended-footer">
    <p class="text-white">
        Celem kampanii jest zwrócenie uwagi na bezpieczeństwo i znaczenie siły drobiazgów. Podczas hamowania,
        działanie siły bezwładności powoduje znaczne zwiększenie masy przedmiotów, które niezabezpieczone stają się
        zagrożeniem dla pasażerów. Sposób przedstawienia zjawiska jest uproszczeniem, a w jego opisie pojawiają się
        powszechnie używane sformułowania, mające uczynić komunikat zrozumiałym dla wszystkich.<br><br>
        Dane na podstawie świadectw homologacji typu. Od 1 września 2018 r. wszystkie nowe pojazdy wprowadzane do obrotu
        w Unii Europejskiej muszą być badane i homologowane zgodnie z procedurą WLTP określoną w rozporządzeniu Komisji
        (UE) 2017/1151. WLTP zapewnia bardziej rygorystyczne warunki badania i bardziej realistyczne wartości zużycia
        paliwa i emisji CO2 w porównaniu do stosowanej to tej pory metody NEDC. Prezentowane dane dotyczące wartości
        zużycia paliwa i emisji CO2 są danymi zgodnymi ze świadectwem homologacji typu wyznaczonymi zgodnie z procedurą
        WLTP.<br/><br/> Więcej informacji na temat WLTP na stronie:
        <a href="www.skoda-wltp.pl" style="text-decoration: none">www.skoda-wltp.pl</a><br/><br/> Zasięg dla samochodów
        elektrycznych lub zasięg w trybie elektrycznym dla hybryd typu Plug-In może się różnić w zależności od wersji i
        wyposażenia oraz zamontowanych akcesoriów. W praktyce rzeczywisty zasięg różni się w zależności od stylu jazdy,
        prędkości, korzystania z dodatkowych odbiorników energii, temperatury zewnętrznej, liczby pasażerów, obciążenia
        ładunkiem i topografii terenu.<br/><br/> Podane ceny obejmują podatek VAT (23%).<br/><br/> Wszelkie prezentowane
        informacje, w szczególności zdjęcia, wykresy, specyfikacje, opisy, rysunki lub parametry techniczne nie stanowią
        oferty w rozumieniu Kodeksu cywilnego oraz nie są wiążące i mogą ulec zmianie bez wcześniejszego powiadomienia.
        Prezentowane informacje nie stanowią zapewnienia w rozumieniu art. 5561§2 Kodeksu cywilnego.<br/><br/> ŠKODA
        zastrzega sobie możliwość wprowadzenia zmian w prezentowanych wersjach. Przedstawione detale wyposażenia mogą
        różnić się od specyfikacji przewidzianej na rynek polski. Zamieszczone zdjęcia mogą przedstawiać wyposażenie
        opcjonalne, dostępne za dopłatą. Wiążące ustalenie ceny, wyposażenia i specyfikacji pojazdu następują w umowie
        sprzedaży, a określenie parametrów technicznych zawiera świadectwo homologacji typu pojazdu. Zastrzegamy sobie
        prawo do zmian i pomyłek. Wszelkie informacje prezentowane na stronie są aktualne na dzień ich zamieszczania. W
        celu uzyskania najnowszych informacji prosimy kontaktować się z Autoryzowanym Dealerem Marki ŠKODA.<br/> <br/>Wszystkie
        produkowane obecnie samochody marki ŠKODA są wykonywane z materiałów spełniających pod względem możliwości
        odzysku i recyklingu wymagania określone w normie ISO 22628 i są zgodne z europejskimi świadectwami homologacji
        wydanymi wg dyrektywy 2005/64/WE. Volkswagen Group Polska sp. z o.o. podlega obowiązkowi zapewnienia wszystkim
        użytkownikom samochodów marki ŠKODA sieci odbioru pojazdów po wycofaniu ich z eksploatacji, zgodnie z
        wymaganiami ustawy z 20 stycznia 2005 r. o recyklingu pojazdów wycofanych z eksploatacji (t.j. D z.U. z 2020 r.,
        poz. 256). Więcej informacji dotyczących ekologii znajdą Państwo na stronach:
        <a href="https://www.skoda-auto.pl/swiat-skody/recycling-samochodow" style="text-decoration: none">https://www.skoda-auto.pl/swiat-skody/recycling-samochodow</a>
    </p>
</div>
