import {Component, OnInit} from '@angular/core';
import {Options} from "@angular-slider/ngx-slider";
import {itemsList} from "../power";
import {Items} from "../power.model";

@Component({
    selector: 'app-speed',
    templateUrl: './speed.component.html',
    styleUrls: ['./speed.component.scss']
})
export class SpeedComponent implements OnInit {
    speed: number = 0;
    weight: string|number = 0;
    value: number = 0;
    crashDistance: number = 0.491;
    options: Options = {
        floor: 0,
        ceil: 120,
        showSelectionBar: true
    };
    list: Items[] = itemsList;
    copy: Items[];

    constructor() {
    }

    ngOnInit(): void {
        this.changeWeight(this.speed);
    }

    changeWeight(speed) {
        this.copy = [...this.list];
        this.copy = this.calculateSpeed(speed, this.list);
        let fullWeight = 0;
        this.copy.map(item => {
            if (+item.weight) {
                fullWeight += +item.weight;
            }
        });
        this.weight = fullWeight.toFixed(1);
    }

    private calculateSpeed(speed, list) {
        return list.map((item, id) => {
            let obj;
            if (speed > 0 && item.weight)
                obj = {...item, weight: this.getCollisionWeight(speed, item.weight)}
            else
                obj = {...item, weight: list[id].weight};
            return obj;
        })
    }

    private getCollisionWeight(spd, actWeight) {
        let msSpeed = spd * 1000 / 3600;
        let a = msSpeed * msSpeed / (this.crashDistance * 2);
        let gs = a / 9.81;
        if (gs < 1.0) gs = 1 + gs;
        let w = actWeight * gs;
        return w.toFixed(1);
    }
}
