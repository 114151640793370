import { Component, AfterViewInit, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    constructor(
        private renderer: Renderer2,
        private readonly elementRef: ElementRef,
        private route: ActivatedRoute
    ) { }


    title = 'safety';

    scrollToSection(href, id) {
        const link = window.location.href;
        const reqEx = href;

        if (reqEx.test(link)) {
            setTimeout(() => {
                const sectionForm = document.getElementById(id);
                sectionForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 200);
        }
    }

    scrollToSectionSearch(href, id) {
        const link = window.location.search;
        const reqEx = new RegExp(href);
        console.log('link to', link, 'req to ', reqEx);

        if (reqEx.test(link)) {
            setTimeout(() => {
                const sectionForm = document.getElementById(id);
                sectionForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 1000);
        }
    }

    ngOnInit() {

        this.route.queryParams.subscribe(data => {
            if (data.section === 'test-drive') {
                this.scrollToSectionSearch(data.section, 'form');
            }

            if (data.section === 'safety-system') {
                this.scrollToSectionSearch(data.section, 'safety');
            }
        });
    }

    ngAfterViewInit() {
        this.scrollToSection(/nasze-doswiadczenie/, 'experience');
        this.scrollToSection(/systemy-bezpieczenstwa/, 'safety');
        this.scrollToSection(/modele/, 'models');
        this.scrollToSection(/jazda-probna/, 'form');
    }
}

