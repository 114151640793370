<section id="models">
  <h2>Poznaj modele ŠKODY</h2>

    <section class="models-carousel" *ngIf="!isMobileDevice">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <app-modele-item [item]="slide"></app-modele-item>
        </div>
      </ngx-slick-carousel>
    </section>

    <section class="models-carousel-mobile slideMobile">
      <div  *ngFor="let slide of slides; let i = index" >
        <app-modele-item [item]="slide"  *ngIf="i < numberSlideShow"></app-modele-item>
      </div>
      <button
        (click)="mobileArrayLenght(); showMobile = !showMobile"
        class=" btn-green btn-model">
        {{ contentBtnMobile }}
      </button>
    </section>

</section>

