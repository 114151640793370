import { Component, Input, OnInit } from '@angular/core';

import { SystemGroup } from './../system-group.model';

@Component({
    selector: 'app-mobile-system-group',
    templateUrl: './mobile-system-group.component.html',
    styleUrls: ['./mobile-system-group.component.scss']
})
export class MobileSystemGroupComponent implements OnInit {
    currentSlideIndex = 0;
    @Input() systemGroup: SystemGroup;
    isToggled = false;
    configSettings = {
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        swipe: false
    };
    slickElement: any;

    getCurrentSlideIndex() {
        return this.slickElement.currentIndex;
    }

    ngOnInit() {
        if (this.slickElement) {
            this.slickElement.initSlick(this.configSettings);
        }
    }

    slideConfig(slickElement) {
        this.slickElement = slickElement;
        return this.configSettings;
    }
}
