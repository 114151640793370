import { Component, ViewChild, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';

import { AppService } from '../app.service';
import { carArray } from '../cars';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy  {
    navbarOpen = false;
    @ViewChild('toggler') toggler: ElementRef;
    @ViewChild('priceList') togglerPriceList: ElementRef;
    pricelistSubmenuItems = carArray;
    roadShowSubscription: Subscription;

    constructor(
        private renderer: Renderer2,
        private appService: AppService,
    ) { }

    isShowPartMenu;

    ngOnInit() {
        this.roadShowSubscription = this.appService.getisRoadShow().subscribe((data) => this.isShowPartMenu = data);
    }

    ngOnDestroy() {
        this.roadShowSubscription.unsubscribe;
    }


    hideMobileNav() {
        this.renderer.removeClass(this.toggler.nativeElement, 'show');
        this.renderer.addClass(this.toggler.nativeElement, 'hide');
    }

    navigateTo(sectionId) {
        if (!this.appService.isHomePage) {
            this.appService.currentSectionName = sectionId;
        } else {
            this.appService.setNavSectionRef(sectionId);
        }
        this.hideMobileNav();
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

    trackSyneriseEvent(eventName: string, modelName?: string) {
        this.appService.trackSyneriseEvent(eventName, modelName);
    }

    gaFromQuiz(eventName, eventLabel) {
        const link = window.location.href;
        const reqEx = /quiz/;

        if (reqEx.test(link)) {
            this.appService.trackGAEventClickQuiz(eventName, eventLabel, '');
        }
    }

    scrollToSection(sectionId, time) {
        this.appService.scrollToSection(sectionId, time);
    }

    pushDataLayer(eventName, eventLabel, eventCategory) {
        this.appService.trackGAEventOnPage(eventName, eventLabel, eventCategory);
    }
}
